import React, { useEffect } from 'react';

const Googlepixel = () => {

        useEffect(() => {
            const img = new Image();
            img.src = 'https://trk.mrndigital.in/pixel?av=65438668f136ac49a726861c';
            img.alt = 'Google Pixel';
            document.body.appendChild(img);

            // Clean up the image element when the component is unmounted
            return () => {
            document.body.removeChild(img);
            };
        }, []); // Empty dependency array ensures the effect runs once after the initial render

  return <div> </div>;
};


export default Googlepixel;