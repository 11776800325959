import React from "react";
import './terms.css';

const TermsAndCondition = () => {
  return (
    <div className="terms-and-conditions" >
      <h1>Terms and Conditions for Collecting Personal Information</h1>

      <section className="terms-and-conditions__section">
        <h2>1. Introduction</h2>
        <p>
          These Terms and Conditions outline the policies and procedures for
          collecting and handling personal information, including names and phone
          numbers, when you interact with Audio First Commerce Private. Limited
          ("we," "us," or "our"). By providing your personal information, you
          acknowledge and consent to the terms and conditions set forth below.
        </p>
      </section>

      <section className="terms-and-conditions__section">
        <h2>2. Information Collection</h2>
      
        <h3>2.1. Data Collected</h3>
        <p>
          We collect and store personal information, including but not limited
          to your name and phone number when you voluntarily provide it to us,
          such as when you fill out a contact form, sign up for our services, or
          participate in surveys and promotions.
        </p>
      </section>

      <section className="terms-and-conditions__section">
        <h2>3. Use of Information</h2>
        <h3>3.1. Purpose</h3>
        <p>
          Your personal information is collected for the sole purpose of
          providing our products and services, communicating with you, improving
          our offerings, and ensuring compliance with legal and regulatory
          requirements.
        </p>
      </section>

      <section className="terms-and-conditions__section">
        <h2>4. Data Security</h2>
   
        <h3> 4.1 Protection</h3>
        <p>
          We take all reasonable measures to protect your personal information
          from unauthorized access, disclosure, alteration, or destruction.
          However, no data transmission over the internet or storage method can be
          guaranteed as 100% secure, and we cannot ensure or warrant the security
          of any information you transmit to us.
        </p>
      </section>

      <section className="terms-and-conditions__section">
        <h2> 5. Sharing Information</h2>
        <h3> 5.1 Third Parties</h3>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties without your consent, except when necessary for the
          operation of our services, compliance with legal obligations, or
          protection of our rights, property, or safety.
        </p>
      </section>

      <section className="terms-and-conditions__section">
        <h2> 6. Cookies and Tracking Technologies</h2>
        <h3>6.1 Cookies</h3>
        <p>
          Our website may use cookies and other tracking technologies to enhance
          your user experience and gather data about how you interact with our
          website. You can manage your cookie preferences in your browser
          settings.
        </p>
      </section>

      <section className="terms-and-conditions__section">
        <h2>7. Your Rights</h2>
        <h3>7.1 Access and Correction</h3>
        <p>
          You have the right to access, correct, or request the deletion of your
          personal information held by us. To exercise these rights, please
          contact us at hi@hivoco.com.
        </p>
      </section>

      <section className="terms-and-conditions__section">
        <h2>8. Changes to the Privacy Policy</h2>
        <h3>8.1 Updates</h3>
        <p>
          We reserve the right to update these terms and conditions at any time.
          Any changes will be posted on this page, and it is your responsibility
          to review these terms periodically.
        </p>
      </section>
      <section className="terms-and-conditions__section">
      <h2>9. Consent</h2>
      <p>
      By providing your personal information to us, you acknowledge that you have read, understood, and agree to these terms and conditions.
      </p>
    </section>

      <section className="terms-and-conditions__section">
        <h2>10. Contest</h2>
        <p>
          The Win a Gold Coin contest is run independently by Senco Gold
          Limited (Company). Registered users can participate in the contest by
          visiting the showroom of the Company. Participation and adjudication
          of winners is at the sole discretion of the Company.
        </p>
      </section>

      <section className="terms-and-conditions__section">
        <h2>11. Contact Us</h2>
        <p>
          If you have any questions or concerns about these terms and conditions
          or our privacy practices, please contact us at hi@hivoco.com.
        </p>
      </section>
    </div>
  );
};

export default TermsAndCondition;