import React from 'react'
import styles from "./mobile-landing-page.module.css"
import coin from '../images/coin@2x.png';
import sencologo from '../images/logo with website.png'
import content from '../images/Senco 05.11.00_01_57_23.Still001@2x.png'
import ceo from '../images/suv@2x.png';
import director from '../images/Joi@2x.png'
import preist from '../images/laxmi.png' 
import webIcon from '../images/web-icon.png'
import { useNavigate } from 'react-router-dom';


export default function Mregister() {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper} >
  <div className={styles.header}> <img src={webIcon} alt='webicon' className={styles.webIcon} onClick={()=>window.open('https://sencogoldanddiamonds.com/', '_blank') } /></div>
   
  <div className={styles.container}>
   <img src={sencologo} onClick={()=>window.open('https://sencogoldanddiamonds.com/', '_blank') } alt="" className={styles.logo} />
    <img src={coin} alt="" className={styles.coin}/>
    <img src={content} alt="" className={styles.content} />
    <span className={styles.subText}>
            Get a personalized Ma Laskhmi Mantra Pooja <br /> with Your Name and
            a chance to win a Senco Gold Coin{" "}
          </span>
          <button className={styles.applyBtn} onClick={()=>navigate("/RegisterPage")} >APPLY NOW</button>
          <span className={styles.footer} onClick={()=>navigate('/TermsAndCondition')} > *Terms and condition apply</span>
          <div className={styles.imgSection}>
          <img alt="ceo" src={ceo} className={styles.ceoPic}/>
          <img alt="director" src={director} className={styles.directorPic} />
          <img alt="priest" src={preist} className={styles.priestPic} />
         
          </div>
         
    </div>
   </div>
  )
}
