import React, { useState } from 'react';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';
import styles from "./registerPage.module.css";
import webIcon from '../images/web-icon.png'
import backIcon from '../images/back-arrow.png'
// import circle from '../images/circle@2x.png'

export default function RegisterPage() {

  const [email, setEmail] = useState('');
  const [emailotp, setEmailOtp ] = useState('');
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [mobilenum, setMobilenum] = useState('');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const otpRegex = /^\d{4}$/;
  const nameRegex =  /^[a-zA-Z]{4,30}$/;
  const surnameRegex =  /^[a-zA-Z]{4,30}$/;
  const indianPhoneNumberRegex = /^[6789]\d{9}$/;

  const navigate = useNavigate();

  const handleOTPButtonClick = async (e) => {
    e.preventDefault();

      if(!emailRegex.test(email)){
             document.getElementById("emailErrors").textContent = "Invalid email address";
             return;
       }
       else{
             try {
                 const lowercaseEmail = email.toLowerCase();
                 const response = await axios.get(`https://api.sencocelebrations.com/api/get_otp/?email_id=${lowercaseEmail}`);
                 console.log(' OTP API Response:', response.data);

                 if(response.status === 200){
                   document.getElementById("Otpapiresponse").textContent = response.data.msg;
                   }      
                   else{
                       document.getElementById("Otpapiresponse").textContent = response.data.msg;
                   }

               } catch (error) {
                 console.error('Error:', error);
                 // Handle error if the API call fails
               }
       }

};


const handleRegButtonClick = async (e) => {
             e.preventDefault();
             console.log(  "email_id", email,
             "email_otp ", emailotp,
             "first_name",firstname,
             "surname", surname,
             "mobilenum", mobilenum);

     if (!emailRegex.test(email)) {
         document.getElementById("emailErrors").textContent = "Invalid email address";
         return;
     }
      else if(!otpRegex.test(emailotp)) {
          document.getElementById("otpError").textContent = "Invalid OTP";
          return;
     }   
      else if(!nameRegex.test(firstname)) {
           document.getElementById("firstnameError").innerText = "Invalid Firstname, a minimum of four characters is required.";
           return;
     }
      else if (!surnameRegex.test(surname)) {
           document.getElementById("surnameError").innerText = "Invalid Surname, a minimum of four characters is required.";
           return;
      }
      else if (!indianPhoneNumberRegex.test(mobilenum)) {
             document.getElementById("mobError").innerText = "Invalid Mobile Number, provide your 10 digit phone number.";
             return;
       }
      else{

         try {
                 const lowercaseEmail = email.toLowerCase();
                 const lowername = firstname.toLowerCase();
                 const mfirstname = lowername.charAt(0).toUpperCase() + lowername.slice(1);
                 const lowersurname = surname.toLowerCase();
                 const msurname = lowersurname.charAt(0).toUpperCase() + lowersurname.slice(1);

                 const response = await axios.post('https://api.sencocelebrations.com/api/create_user/', {
                   email_id: lowercaseEmail,
                   email_otp: emailotp,
                   first_name: mfirstname,
                   last_name: msurname,
                   mobile: mobilenum,
                 }, {
                   headers: {
                     'Content-Type': 'multipart/form-data'
                   }
                 });
                     console.log('Create API Response:', response.data);
                     if(response.status === 200 && response.data.status!=='failed'){
                         // document.getElementById("apiresponseMsg").textContent = response.data.msg;
                         console.log(response.data)    
                         navigate(`/Thanks`);
                     }      
                     else{
                      console.log(response.data)      
                      document.getElementById("apiresponseMsg").textContent = response.data.msg;
                     }

               } catch(error) {
                       console.error('Error:', error);
               }

          } 

  };

  return (
    <div className={styles.wrappers}>
      <div className={styles.header}>
      <div className={styles.backSection} onClick={()=>navigate("/LandingPage")}> <img src={backIcon}  alt="" className={styles.backIcon} />
      <span>Back</span>
      </div>
      <img src={webIcon} alt="Senco logo" onClick={()=>window.open('https://sencogoldanddiamonds.com/', '_blank') } />
      </div>
      <div className={styles.container}>
 

        <div className={styles.formWrapper}>
          <div className="header">
            <div className="form-head">
             
              <h2>Register Now</h2>
             
            </div>
            <span className={styles.subHead}>Submit your name!</span>
          </div>

          <span id="apiresponseMsg"></span> 
          <form className="registeration-form" style={{gap:'6px'}}  onSubmit={handleRegButtonClick}>

            <span id="firstnameError"></span> 
            <label>First Name*</label>
            <input style={{height:'2.5rem'}} type="text" name="firstname" placeholder='First Name' 
              value={firstname} 
               onChange={(e) => {  setFirstname(e.target.value)
               if(!nameRegex.test(e.target.value)) {
                   document.getElementById("firstnameError").textContent = "Invalid Firstname, a minimum of four characters is required.";
               }else{
                   document.getElementById("firstnameError").textContent = "";
               }
             }}  required  />

            <span id="surnameError"></span> 
            <label>Surname*</label>
            <input style={{height:'2.5rem'}} type="text" name="Surname" placeholder='Surname'
            value={surname} 
            onChange={(e) => {  setSurname(e.target.value)
                if(!surnameRegex.test(e.target.value)) {
                    document.getElementById("surnameError").textContent = "Invalid Surname, a minimum of four characters is required.";
                }else{
                    document.getElementById("surnameError").textContent = "";
                }
            }}  required />

            <span id="mobError"></span> 
            <label>Phone*</label>
            <input style={{height:'2.5rem'}} type="text" name="mobilenumber" placeholder='Mobile Number' 
            value={mobilenum} 
            onChange={(e) => {  setMobilenum(e.target.value)
              if(!indianPhoneNumberRegex.test(e.target.value)) {
                    document.getElementById("mobError").textContent = "Invalid Mobile Number, provide your 10 digit phone number.";
              }else{
                    document.getElementById("mobError").textContent = "";
              }
            }}  required  />

            <span id="emailErrors"></span> 
            <label>Email*</label>
            <input style={{height:'2.5rem'}} 
              type="text" name="email" placeholder='Email ID' 
              value={email} 
              onChange={(e) => { setEmail(e.target.value)
             if(!emailRegex.test(e.target.value)) {
                    document.getElementById("emailErrors").textContent = "Invalid email address";
              }else{
                    document.getElementById("emailErrors").textContent = "";
              }
              }}  required />

       <div className='otpBtnWrapper'>     <button className="otp-btn" id={styles.otpBtn} onClick={handleOTPButtonClick} >SEND OTP</button></div>

            <span id="Otpapiresponse"></span>

            <span id="otpError"></span> 
            <label>OTP*</label>
            <input style={{height:'2.5rem'}} 
                type="text" name="otp" placeholder='Email OTP' 
                value={emailotp} 
                onChange={(e) => { setEmailOtp(e.target.value)
                  if(!otpRegex.test(e.target.value)) {
                      document.getElementById("otpError").textContent = "Invalid OTP";
                  }   
                  else{
                      document.getElementById("otpError").textContent = "";
                  }
                }} required  />

            <button style={{height:'2.5rem', fontSize :'18px'}} className="register-btn" type="submit">REGISTER NOW</button>

          </form>
          <span className={styles.footer} onClick={()=>navigate('/TermsAndCondition')} >*Terms and Conditions apply</span>
        </div>
      </div>
    </div>
  );
}
