import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Thanks from './Thanks';
import LandingPage from './mobile/Mregister';
import RegisterPage from './mobile/RegisterPage';
import Register from './Register';
import TermsAndCondition from './TermsAndCondition';


function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  return (
        <>
            <Router>
            <Routes>
              <Route path="/" element={isMobile ? <LandingPage /> : <Register />} />
              <Route path="/Register" element={<Register />} />
              <Route path="/LandingPage" element={<LandingPage />} />
              <Route path="/RegisterPage" element={<RegisterPage />} />
              <Route path="/Thanks" element={<Thanks />} />
              <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
            </Routes>
          </Router>

        </>
  );
}

export default App;
